#sidebar{
    position: fixed;
    right: 12px;
    top: 50vh;
    transform: translateY(-50%);
    background: var(--secondary);
    border-radius: 16px;
    width: 40px;
    padding: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    opacity: 1;
    visibility: visible;
    transition: visibility 0s, opacity 0.25s linear;
}

#sidebar.hide{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.25s, opacity 0.25s linear;
}

#sidebar ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#sidebar ul li{
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#sidebar ul li a{
    color: white;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#sidebar ul li a:hover{
    border-radius: 12px;
    background: white;
    box-shadow: 0 0 1px 1px white;
    color: var(--secondary);

}

@media screen and (max-width:800px){
    #sidebar{
        width: 32px;
        padding: 4px 0;
    }

    #sidebar ul{
        gap: 4px;
    }

    #sidebar ul li{
        width: 24px;
    }
    
    #sidebar ul li a{
        width: 24px;
        height: 24px;
    }

    #sidebar ul li a svg{
        height: 0.75em;
    }
}


@media screen and (max-width:350px){
    #sidebar{
        right: 4px;
        width: 24px;
        padding: 4px 0;
    }

    #sidebar ul{
        gap: 4px;
    }

    #sidebar ul li{
        width: 16px;
    }
    
    #sidebar ul li a{
        width: 16px;
        height: 16px;
    }
}