footer .footerContainer{
    height: 350px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
}

footer .footerContainer h5{
    padding: 0 0 5px;
    color: var(--secondary);
    border-bottom: 2px solid var(--secondary);
    font-weight: bold;
}

body.dark footer .footerContainer h5{
    color: var(--primary);
    border-bottom: 2px solid var(--primary);
}

footer .footerContainer ul{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

footer .footerContainer .footerLeft{
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

footer .footerContainer .footerLeft img{
    width: 300px;
}

footer .footerContainer .footerCenter{
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

footer .footerContainer .footerCenter h5,
footer .footerContainer .footerCenter ul
{
    width: 100%;
}

footer .footerContainer .footerCenter ul li a{
    color: var(--secondary);
    text-decoration: none;
    font-weight: bold;
}

footer .footerContainer .footerCenter ul li a:hover{
    color: var(--primary);
    padding-left: 10px;
}

body.dark footer .footerContainer .footerCenter ul li a{
    color: var(--primary);
}

body.dark footer .footerContainer .footerCenter ul li a:hover{
    color: var(--secondary);
}

footer .footerContainer .footerRight{
    width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

footer .footerContainer .footerRight span{
    font-weight: bold;
}

footer .footerContainer .footerRight h1{
    font-size: 1rem;
    display: inline;
    font-weight: 400;
}

footer .footerContainer .footerRight ul.contactUs li svg,
footer .footerContainer .footerRight ul.contactUs li span{
    color: var(--secondary);
}

body.dark footer .footerContainer .footerRight ul.contactUs li svg,
body.dark footer .footerContainer .footerRight ul.contactUs li span{
    color: var(--primary);
}

footer .footerContainer .footerRight ul.footerSocial{
    margin-top: 20px;
    flex-direction: row;
    gap: 25px;
}

footer .footerContainer .footerRight ul.footerSocial li svg{
    font-size: 24px;
    width: 24px;
    height: 24px;
    color: var(--secondary);
}

footer .footerContainer .footerRight ul.footerSocial li:hover svg{
    color: var(--primary);
}

body.dark footer .footerContainer .footerRight ul.footerSocial li svg{
    color: var(--primary);
}

body.dark footer .footerContainer .footerRight ul.footerSocial li:hover svg{
    color: var(--secondary);
}

footer hr{
    margin-bottom: 0;
}

@media screen and (max-width:950px){
    footer .footerContainer{
        height: 272px;
    }
    
    footer .footerContainer .footerLeft .logo{
        width: 200px;
    }
    
    footer .footerContainer h5
    {
        font-size: 1rem;
    }

    footer .footerContainer ul{
        font-size: 0.8rem;
    }
    footer .footerContainer .footerRight ul.footerSocial{
        margin-top: 5px;
    }

    footer .footerContainer .footerRight ul.footerSocial li svg{
        width: 20px;
        height: 20px;
    }
}

@media screen and (max-width:768px) {
    footer .footerContainer .footerLeft{
        display: none;
    }
}

@media screen and (max-width:480px) {
    footer .footerContainer{
        height: unset;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }
    footer .footerContainer .footerCenter,
    footer .footerContainer .footerRight{
        width: 75vw;
    }
}

@media screen and (max-width:300px){
    footer .footerContainer{
        flex-direction: column;
        height: unset;
    }

    footer .footerContainer .footerCenter,
    footer .footerContainer .footerRight
    {
        width: 200px;
    }
    
}