section#dentistCardsContainer .dentistCard .box  div p a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    background-color: white;
    border-radius: 8px;
}

section#dentistCardsContainer .dentistCard .box  div p a svg{
    width: 24px;
    height: 24px;
}

section#dentistCardsContainer .dentistCard .box  div p a:hover svg{
    color: white !important;
}

section#dentistCardsContainer .dentistCard .box  div p a svg.linkedIn{
    color: #0077b5;
}

section#dentistCardsContainer .dentistCard .box  div p a:has(svg.linkedIn):hover{
    background: #0077b5;
}

section#dentistCardsContainer .dentistCard .box  div p a svg.facebook{
    color: #316FF6;
}

section#dentistCardsContainer .dentistCard .box  div p a:has(svg.facebook):hover{
    background: #316FF6;
}

section#dentistCardsContainer .dentistCard .box  div p a svg.instagram{
    color: #fa7e1e;
}

section#dentistCardsContainer .dentistCard .box  div p a:has(svg.instagram):hover{
    background: #fa7e1e;
}

section#dentistCardsContainer .dentistCard .box  div p a svg.twitter{
    color: #1DA1F2;
}

section#dentistCardsContainer .dentistCard .box  div p a:has(svg.twitter):hover{
    background: #1DA1F2;
}

@media screen and (max-width:800px){
    section#dentistCardsContainer .dentistCard .box  div p a{
        width: 20px;
        height: 20px;
    }
    
    section#dentistCardsContainer .dentistCard .box  div p a svg{
        width: 16px;
        height: 16px;
    }
}