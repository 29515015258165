.treatmentCards{
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    align-items: center;
}

.treatmentCards .treatment{
    max-width: 320px;
    height: 320px;
    border: 2px solid var(--secondary);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}

body.dark .treatmentCards .treatment{
    border: 2px solid var(--primary);
}

.treatmentCards .treatment .imageContainer{
    overflow: hidden;
    height: 100%;
}

.treatmentCards .treatment img{
    width: 100%;
    height: 100%;
    transition: .5s;
}

body.dark .treatmentCards .treatment:hover{
    box-shadow: 0 0 5px 5px var(--primary);
}

.treatmentCards .treatment:hover{
    box-shadow: 0 0 5px 5px var(--secondary);
}

.treatmentCards .treatment:hover img{
    transform: scale(1.25);
    filter: brightness(0.5);
}

.treatmentCards .treatment h2{
    margin-bottom: 0;
    padding: 15px;
    font-size: 1.25rem;
    width: 100%;
    text-align: center;
    background-color: var(--secondary);
    color: white;
}

body.dark .treatmentCards .treatment h2{
    background-color: var(--primary);
}

@media screen and (max-width:800px){
    .treatmentCards .treatment{
        width: 200px;
        height: 200px;
    }
    .treatmentCards .treatment h2{
        font-size: 1rem;
    }
}