.carousel-caption{
    bottom: unset !important;
    top: 50%;
    transform: translateY(-50%);
}

.carousel-caption h3{
    font-size: 64px !important;
}

.carousel-caption p{
    font-size: 32px !important;
}

.carousel-item img{
    width: 100%;
    height: calc(100vh - var(--topbar-height) - var(--navbar-height));
    filter: brightness(0.5);
}

.carousel-item:nth-child(1) img{
    filter: brightness(1);
}

.carousel-item:nth-child(1) h3,
.carousel-item:nth-child(1) p
{
    visibility: hidden;
}

@media screen and (max-width: 960px) {
    .carousel-item img{
        height: unset;
    }
}

@media screen and (max-width: 768px) {
    .carousel-caption h3{
        font-size: 32px !important;
    }
    .carousel-caption p{
        font-size: 20px !important;
    }
    .carousel-indicators button{
        width: 24px !important;
        height: 2px !important;
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon{
        width: 1.5rem !important;
        height: 1.5rem !important;
    }
}

@media screen and (max-width: 400px) {
    .carousel-caption h3{
        font-size: 20px !important;
    }
    .carousel-caption p{
        font-size: 12px !important;
    }
    .carousel-indicators button{
        width: 20px !important;
        height: 2px !important;
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon{
        width: 1rem !important;
        height: 1rem !important;
    }
}

.snowflakes{
    width: 100%;
    height: 100%;
    z-index: 100;
    position: absolute;
    /* backdrop-filter: sepia(0.8); */
    --flakeCount : 10;
}

.snowflake{
    color: white;
    top: -10%;
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    -webkit-animation-name: snowflakes-fall, snowflakes-shake;
    -webkit-animation-duration: var(--fallAnimationDuration), 3s;
    -webkit-animation-timing-function: linear, ease-in-out;
    -webkit-animation-iteration-count: infinite, infinite;
    -webkit-animation-play-state: running, running;
    animation-name: snowflakes-fall, snowflakes-shake;
    animation-duration: var(--fallAnimationDuration), 3s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    animation-play-state: running, running;
    left: calc(var(--i) * (100% / var(--flakeCount)));
    filter: blur(0.5px);
    text-shadow: 0 0 5px white;
    font-size: 2rem;
    /* -webkit-animation-delay: var(--fallDelay), var(--shakeDelay);
    animation-delay: var(--fallDelay), var(--shakeDelay); */
}

@-webkit-keyframes snowflakes-fall {
    0% {
      top: -10%;
    }
    100% {
      top: 100%;
    }
  }
  @-webkit-keyframes snowflakes-shake {
    0% {
      -webkit-transform: translateX(0px);
      transform: translateX(0px);
    }
    50% {
      -webkit-transform: translateX(80px);
      transform: translateX(80px);
    }
    100% {
      -webkit-transform: translateX(0px);
      transform: translateX(0px);
    }
  }
  @keyframes snowflakes-fall {
    0% {
      top: -10%;
    }
    100% {
      top: 100%;
    }
  }
  @keyframes snowflakes-shake {
    0% {
      transform: translateX(0px);
    }
    50% {
      transform: translateX(80px);
    }
    100% {
      transform: translateX(0px);
    }
  }


  .snowflake:nth-of-type(0) {
    -webkit-animation-delay: 0s, 0s;
    animation-delay: 0s, 0s;
  }
  .snowflake:nth-of-type(1) {
    -webkit-animation-delay: 1s, 1s;
    animation-delay: 1s, 1s;
  }
  .snowflake:nth-of-type(2) {
    -webkit-animation-delay: 6s, 0.5s;
    animation-delay: 4s, 0.5s;
  }
  .snowflake:nth-of-type(3) {
    -webkit-animation-delay: 4s, 2s;
    animation-delay: 3s, 2s;
  }
  .snowflake:nth-of-type(4) {
    -webkit-animation-delay: 2s, 2s;
    animation-delay: 2s, 2s;
  }
  .snowflake:nth-of-type(5) {
    -webkit-animation-delay: 8s, 3s;
    animation-delay: 6s, 3s;
  }
  .snowflake:nth-of-type(6) {
    -webkit-animation-delay: 6s, 2s;
    animation-delay: 5s, 2s;
  }
  .snowflake:nth-of-type(7) {
    -webkit-animation-delay: 2.5s, 1s;
    animation-delay: 2.5s, 1s;
  }
  .snowflake:nth-of-type(8) {
    -webkit-animation-delay: 1s, 0s;
    animation-delay: 1s, 0s;
  }
  .snowflake:nth-of-type(9) {
    -webkit-animation-delay: 3s, 1.5s;
    animation-delay: 3s, 1.5s;
  }

  @media screen and (max-width:960px){
    .snowflake{
        font-size: 1.5rem;
    }
  }

  @media screen and (max-width:800px){
    .snowflake{
        font-size: 1.25rem;
    }
  }

  @media screen and (max-width:600px){
    .snowflake{
        font-size: 1rem;
    }
  }

  @media screen and (max-width:400px){
    .snowflake{
        font-size: 0.75rem;
    }
  }