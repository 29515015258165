#treatmentsContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}

#treatments{
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

#treatments h4{
    position: relative;
    color: var(--secondary);
}

body.dark #treatments h4{
    color: var(--primary);
}

#treatments h4::before{
    content: "";
    position: absolute;
    width: 50px;
    height: 5px;
    border-radius: 10px;
    background-color: var(--secondary);
    top: 13px;
    left: -75px;
}

#treatments h4::after{
    content: "";
    position: absolute;
    width: 50px;
    height: 5px;
    border-radius: 10px;
    background-color: var(--secondary);
    top: 13px;
    right: -75px;
}

body.dark #treatments h4::before,
body.dark #treatments h4::after{
    background-color: var(--primary);
}

@media screen and (max-width:768px){
    #treatmentsContainer{
        padding-bottom: 0;
    }
    #treatments h4{
        font-size: calc(1rem + .6vw);
    }

    #treatments h4::before,
    #treatments h4::after
    {
        height: 4px;
        top: 11px;
    }

    #treatments h4::before{
        left: -65px;
    }

    #treatments h4::after{
        right: -65px;
    }
}

@media screen and (max-width:300px){
    #treatments h4::before,
    #treatments h4::after
    {
        width: 40px;
    }

    #treatments h4::before{
        left: -50px;
    }

    #treatments h4::after{
        right: -50px;
    }
}