body.dark .offcanvas{
    background: var(--bg-dark);
}

.offcanvas .offcanvas-header .btn-close{
    --bs-btn-close-bg: url(../../assets/icons/closeButton.svg);
    opacity: 1;
    box-shadow: none;
}

.offcanvas .offcanvas-header .btn-close:hover{
    box-shadow: 0 0 5px 1px var(--secondary);
}

body.dark .offcanvas .offcanvas-header .btn-close{
    --bs-btn-close-bg: url(../../assets/icons/darkCloseButton.svg);
}

#searchInput{
    border-radius: 10px;
    border: 2px solid var(--secondary);
    height: 40px;
    text-align: center;
    width: 250px;
    color: black;
}

#searchInput::placeholder{
    color: black;
}

#searchInput:focus,
#searchInput:focus-visible{
    outline: none;
    box-shadow: 0 0 5px 2px var(--secondary);
}

body.dark #searchInput{
    background: var(--primary);
}

body.dark #searchInput:focus,
body.dark #searchInput:focus-visible{
    outline: none;
    box-shadow: 0 0 5px 2px var(--primary);
}

.offcanvas .offcanvas-body{
    text-align: center;
}

body.dark .offcanvas .offcanvas-body{
    color: var(--primary);
}

@media screen and (max-width:400px){
    #searchInput{
        width: 150px;
        font-size: 0.75rem;
        height: 30px;
    }
    .offcanvas .offcanvas-header .btn-close{
        width: 0.75rem;
        height: 0.75rem;
        background: transparent var(--bs-btn-close-bg) center / 0.75rem auto no-repeat;
    }

    .offcanvas .offcanvas-body{
        font-size: 0.75rem;
    }
}
